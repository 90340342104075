import React, { useEffect, useState, useRef } from "react";
import { StyleSheet, Text, TextInput, View, Platform } from "react-native";
import { Button } from "react-native-elements";
// import Dialog from "react-native-dialog";

const TeamArea = ({
  team,
  teamsScore,
  prevScore,
  teams,
  setTeams,
  settingUp,
  playMode,
}) => {
  const [isEditor, toggleEditor] = useState(false);
  const web = Platform.OS === "web";

  const serving = (team, player) => {
    let style = [styles.emptyServe];
    if (prevScore === team) {
      if (team === player) {
        if (teamsScore[team] % 2 === 0) {
          style.push(styles.serve);
        }
      } else {
        if (teamsScore[team] % 2 !== 0) {
          style.push(styles.serve);
        }
      }
    }
    style.push([styles[`serve${team}`]]);
    return style;
  };

  const changeName = (pos, text) => {
    const newOwnTeam = [...teams[team]];
    newOwnTeam.splice(pos, 1, text);
    setTeams({ ...teams, [team]: newOwnTeam });
  };

  const inputRef = useRef(null);

  const editNames = (pos) => {
    const textLength = teams[team][pos].length;
    return (
      <TextInput
        ref={inputRef}
        style={
          !!isEditor && !web
            ? styles.input
            : [
                styles.teamAreaText,
                styles.inputBox,
                styles[team === 0 ? "left" : "right"],
                textLength > 12
                  ? dynStyles({ length: textLength }).dynamicFontSize
                  : null,
                web ? styles.webHeight : null,
              ]
        }
        value={teams[team][pos]}
        onChangeText={(text) => changeName(pos, text)}
        onFocus={() => toggleEditor(pos + 1)}
        onEndEditing={() => toggleEditor(false)}
      />
    );
  };

  useEffect(() => {
    if (web && isEditor) {
      const pos = isEditor - 1;
      inputRef.current.focus();
      let name = prompt("Enter the name of the player", teams[team][pos]);
      if (name) {
        changeName(pos, name);
      }
      inputRef.current.blur();
      toggleEditor(false);
    }
  });

  const singlesDisplay = (pos) =>
    playMode || (teamsScore[prevScore] % 2 === 0) === (team === pos);

  // const [visible, setVisible] = useState(false);

  // const EditNameDialog = (pos) => {
  //   const [editingName, editName] = useState(teams[team][pos]);
  //   return (
  //     <View>
  //       <Dialog.Container visible={visible}>
  //         <Dialog.Title>Edit player name</Dialog.Title>
  //         <Dialog.Description>
  //           Type in the name of the player:
  //         </Dialog.Description>
  //         <Dialog.Input
  //           value={editingName}
  //           onChangeText={(text) => editName(text)}
  //         />
  //         <Dialog.Button label="Cancel" onPress={() => setVisible(false)} />
  //         <Dialog.Button
  //           label="OK"
  //           onPress={() => {
  //             if (editingName !== "") {
  //               changeName(pos, editingName);
  //             }
  //             setVisible(false);
  //           }}
  //         />
  //       </Dialog.Container>
  //     </View>
  //   );
  // };

  const lockedNames = (pos) => {
    const textLength = teams[team][pos].length;
    return (
      <Text
        style={[
          styles.teamAreaText,
          styles[team === 0 ? "left" : "right"],
          textLength > 12
            ? dynStyles({ length: textLength }).dynamicFontSize
            : null,
        ]}
        onPress={() => {
          if (Platform.OS === "web") {
            let name = prompt("Enter the name of the player", teams[team][pos]);
            if (name) {
              changeName(pos, name);
            }
          } else {
            // setVisible(true);
          }
        }}
      >
        {teams[team][pos]}
      </Text>
    );
  };

  const playerArea = (pos) => (
    <View style={styles.teamAreaTextArea}>
      {team === 0 && <View style={serving(team, pos)} />}
      <View style={styles.thisPlayerText}>
        {singlesDisplay(pos)
          ? settingUp
            ? editNames(pos)
            : lockedNames(playMode ? pos : 0)
          : null}
      </View>
      {team === 1 && <View style={serving(team, pos)} />}
      {/* {Platform.OS === "android" ? EditNameDialog(pos) : null} */}
    </View>
  );

  return (
    <View style={[styles.teamArea, team === 1 ? styles.teamAreaRight : null]}>
      <View style={styles.teamAreaL2}>
        <View style={styles.playerArea}>{playerArea(0)}</View>
        {playMode ? (
          <View>
            <Button
              title=""
              icon={{ name: "swap-vert", color: "#fff", size: 20 }}
              type="clear"
              onPress={() =>
                setTeams({ ...teams, [team]: [teams[team][1], teams[team][0]] })
              }
            />
          </View>
        ) : (
          <Text
            style={[styles.teamAreaText, styles[team === 0 ? "left" : "right"]]}
          >
            |
          </Text>
        )}
        <View style={styles.playerArea}>{playerArea(1)}</View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  teamArea: {
    flex: 1,
    height: "100%",
  },
  teamAreaL2: {
    flex: 1,
    flexDirection: "column-reverse",
    alignItems: "center",
    justifyContent: "center",
  },
  teamAreaRight: {
    flexDirection: "row-reverse",
  },
  teamAreaTextArea: {
    height: "40%",
    flex: 1,
    flexDirection: "column-reverse",
    alignItems: "center",
    justifyContent: "center",
  },
  teamAreaText: {
    fontSize: 16,
    color: "#fff",
    textAlign: "center",
  },
  inputBox: {
    borderStyle: "solid",
    borderBottomColor: "#fff",
    borderBottomWidth: 1,
    padding: 8,
    paddingTop: 2,
    paddingBottom: 2,
  },
  webHeight: {
    // height: 100,
    width: 100,
    // minWidth: 0,
  },
  input: {
    fontSize: 16,
    color: "#000",
    textAlign: "center",
  },
  playerArea: {
    minWidth: 200,
    height: "40%",
    flexDirection: "column-reverse",
    justifyContent: "center",
    alignItems: "center",
  },
  left: {
    transform: [{ rotate: "270deg" }],
  },
  right: {
    transform: [{ rotate: "90deg" }],
  },
  emptyServe: {
    // width: 20,
    // height: 20,
  },
  serve: {
    width: 20,
    height: 20,
    borderRadius: 20,
    backgroundColor: "#fff",
  },
  serve0: {
    marginBottom: 10,
  },
  serve1: {
    marginTop: 10,
  },
  thisPlayerText: {
    flex: 1,
    justifyContent: "center",
  },
  swapWeb: {
    maxWidth: 30,
  },
});

const dynStyles = (props) =>
  StyleSheet.create({
    dynamicFontSize: {
      fontSize: Math.round(16 - props.length / 3),
    },
  });

export default TeamArea;
