import React from "react";
import { Platform, StyleSheet, View, Alert } from "react-native";
import { Header, Button } from "react-native-elements";

const HeaderBar = ({
  flipMode,
  toggleFlip,
  prevScore,
  setPrevScore,
  settingUp,
  reset,
  setUndoMode,
  playMode,
  setPlayMode,
  swapSides,
  submit,
}) => {
  const onPressReset = () => {
    if (Platform.OS === "web") {
      if (confirm("Are you sure you want to reset?")) {
        reset();
      }
    } else {
      Alert.alert(
        "Reset",
        "Are you sure you want to reset?",
        [
          { text: "OK", onPress: () => reset() },
          { text: "Cancel", style: "cancel" },
        ],
        { cancelable: true }
      );
    }
  };

  const undo = () => {
    setUndoMode(true);
  };

  const HeaderComponent = (
    <View style={styles.header}>
      <Button
        titleStyle={styles.headerButton}
        onPress={onPressReset}
        title="Reset"
        icon={{ name: "replay", size: 20, color: "#fff" }}
        disabled={settingUp || flipMode}
      />
      <Button
        titleStyle={styles.headerButton}
        title="Flip"
        onPress={() => toggleFlip(!flipMode)}
        icon={{ name: "flip", size: 20, color: "#fff" }}
      />
      <Button
        titleStyle={styles.headerButton}
        onPress={undo}
        title="Undo"
        icon={{ name: "undo", size: 20, color: "#fff" }}
        disabled={settingUp || flipMode}
      />
      <Button
        titleStyle={styles.headerButton}
        title="Swap"
        icon={{
          name: "swap-horiz",
          color: "#fff",
          size: 20,
        }}
        onPress={swapSides}
      />
    </View>
  );

  const settingUpComponent = (
    <View style={styles.header}>
      <Button
        titleStyle={styles.headerButton}
        title={playMode ? "Doubles" : "Singles"}
        icon={{
          name: playMode ? "people" : "person",
          color: "#fff",
          size: 20,
        }}
        onPress={() => setPlayMode(!playMode)}
      />
      <Button
        titleStyle={styles.headerButton}
        title="Swap"
        icon={{
          name: "swap-horiz",
          color: "#fff",
          size: 20,
        }}
        onPress={swapSides}
      />
    </View>
  );

  const selectServingSide = (dir) => {
    setPrevScore(dir);
  };

  const ServingSide = ({ dir }) =>
    settingUp && (
      <Button
        titleStyle={styles.headerButton}
        title="Serving"
        disabled={prevScore === dir}
        onPress={() => selectServingSide(dir)}
      />
    );

  const RightComponent = (
    <Button
      titleStyle={styles.headerButton}
      onPress={submit}
      title="Submit"
      icon={{ name: "file-upload", size: 20, color: "#fff" }}
      disabled={settingUp || flipMode}
    />
  );

  return (
    <Header
      leftComponent={settingUp && <ServingSide dir={0} />}
      centerComponent={settingUp ? settingUpComponent : HeaderComponent}
      rightComponent={settingUp ? <ServingSide dir={1} /> : RightComponent}
      backgroundColor="#222"
      elevated={true}
      containerStyle={styles.headerFull}
    />
  );
};

const styles = StyleSheet.create({
  headerFull: {
    height: "17%",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-around",
    width: 300,
  },
  headerButton: {
    fontSize: 12,
  },
});

export default HeaderBar;
