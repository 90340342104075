import { StatusBar } from "expo-status-bar";
import React, { useEffect, useState } from "react";
import _, { add } from "lodash";
import { Pressable, StyleSheet, Text, View, Platform } from "react-native";
import * as ScreenOrientation from "expo-screen-orientation";
import HeaderBar from "./HeaderBar";
import TeamArea from "./TeamArea";
import { SafeAreaView } from "react-native-safe-area-context";
import queryString from "query-string";

const Home = () => {
  const [teamsScore, setTeamsScore] = useState({ 0: 0, 1: 0 });
  const [prevScore, setPrevScore] = useState(0);
  const initPlayers = {
    0: ["Adrian", "Benjamin"],
    1: ["Claudia", "Deborah"],
  };
  const [teams, setTeams] = useState(initPlayers);
  const [history, setHistory] = useState([]);
  const [incrementMode, setIncrementMode] = useState(false);
  const [undoMode, setUndoMode] = useState(false);
  const [flipMode, toggleFlip] = useState(false);
  const [playMode, setPlayMode] = useState(1);

  const query = queryString.parse(location.search);

  useEffect(() => {
    if (query.players) {
      const queryPlayers = query.players.split(",");
      if (queryPlayers.length === 4) {
        setTeams({
          0: queryPlayers.slice(0, 2),
          1: queryPlayers.slice(2),
        });
      } else {
        setPlayMode(0);
        setTeams({
          0: [queryPlayers[0], ""],
          1: ["", queryPlayers[1]],
        });
      }
    }
  }, []);

  const settingUp = history.length === 0;

  useEffect(() => {
    const lockOr = async () => {
      if (Platform.OS === "web") {
        await ScreenOrientation.lockPlatformAsync({
          screenOrientationLockWeb:
            ScreenOrientation.WebOrientationLock.LANDSCAPE,
        });
      } else {
        await ScreenOrientation.lockAsync(
          ScreenOrientation.OrientationLock.LANDSCAPE
        );
      }
    };

    lockOr();
  }, []);

  const increment = (team) => {
    if (prevScore === team && playMode) {
      setTeams({ ...teams, [team]: [teams[team][1], teams[team][0]] });
    }
    if (!playMode && settingUp) {
      setTeams({ ...teams, 1: [teams[1][1], teams[1][0]] });
    }
    setTeamsScore({ ...teamsScore, [team]: teamsScore[team] + 1 });
    setPrevScore(team);
    setIncrementMode(true);
  };

  useEffect(() => {
    if (incrementMode) {
      setHistory([...history, { score: teamsScore, teams, prevScore }]);
      setIncrementMode(false);
    }
    if (undoMode && history.length > 1) {
      const latest = _.nth(history, -2);
      setTeamsScore(latest.score);
      setTeams(latest.teams);
      setPrevScore(latest.prevScore);
      setHistory(_.dropRight(history));
      setUndoMode(false);
    }
    if (undoMode && history.length === 1) {
      reset();
      setUndoMode(false);
    }
  }, [incrementMode, undoMode]);

  const reset = () => {
    setTeamsScore({ 0: 0, 1: 0 });
    setHistory([]);
    if (!playMode) {
      setTeams({ 0: teams[0], 1: [teams[1][1], teams[1][0]] });
    }
  };

  const swapSides = () => {
    if (playMode) {
      setTeams({ 0: teams[1], 1: teams[0] });
    } else {
      setTeams({
        0: [teams[1][1], teams[1][0]],
        1: [teams[0][1], teams[0][0]],
      });
    }
  };

  const submit = () => {
    const allPlayers = playMode
      ? `${teams[0].join(",")},${teams[1].join(",")}`
      : `${teams[0][0]},${teams[1][0]}`;
    const scores = `${teamsScore[0]},${teamsScore[1]}`;
    const formatHistoryToString = (arr) => {
      let binStr = arr.reduce((acc, { prevScore }) => acc + prevScore, "");

      let hexStr = parseInt(binStr, 2).toString(16);

      const binHexDiff = Math.ceil(binStr.length / 4) - hexStr.length;

      if (binHexDiff > 0) {
        hexStr = "0".repeat(binHexDiff) + hexStr;
      }

      return hexStr;
    };
    window.open(
      `https://letscore.xyz/home/badminton/add?players=${allPlayers}&score=${scores}&history=${formatHistoryToString(
        history
      )}
        `
    );
  };

  return (
    <SafeAreaView style={styles.view}>
      <HeaderBar
        flipMode={flipMode}
        toggleFlip={toggleFlip}
        prevScore={prevScore}
        setPrevScore={setPrevScore}
        settingUp={settingUp}
        reset={reset}
        setUndoMode={setUndoMode}
        playMode={playMode}
        setPlayMode={setPlayMode}
        swapSides={swapSides}
        submit={submit}
      />
      {!flipMode ? (
        <View style={styles.container}>
          <TeamArea
            team={0}
            teamsScore={teamsScore}
            prevScore={prevScore}
            teams={teams}
            setTeams={setTeams}
            settingUp={settingUp}
            playMode={playMode}
          />
          {[0, 1].map((team) => (
            <Pressable
              onPress={() => increment(team)}
              style={[styles.score, styles[`color${team}`]]}
              key={`team-${team}`}
            >
              <Text style={styles.text}>{teamsScore[team]}</Text>
            </Pressable>
          ))}
          <TeamArea
            team={1}
            teamsScore={teamsScore}
            prevScore={prevScore}
            teams={teams}
            setTeams={setTeams}
            settingUp={settingUp}
            playMode={playMode}
          />
          {/* <StatusBar style="light" /> */}
        </View>
      ) : (
        <View style={styles.container}>
          {[1, 0].map((team) => (
            <View
              onPress={() => increment(team)}
              style={[styles.score, styles[`color${team}`]]}
              key={`team-${team}`}
            >
              <Text style={styles.text}>{teamsScore[team]}</Text>
            </View>
          ))}
          <StatusBar style="auto" />
        </View>
      )}
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  view: {
    flex: 1,
    width: "100%",
    overflow: "hidden",
  },
  container: {
    flex: 1,
    backgroundColor: "#000",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  score: {
    flex: 8,
    height: "90%",
    justifyContent: "center",
    alignItems: "center",
    margin: 10,
  },
  color0: {
    backgroundColor: "#009688",
  },
  color1: {
    backgroundColor: "#ff7043",
  },
  text: {
    color: "#fff",
    fontSize: 150,
  },
});

export default Home;
